import React from 'react'
import {Link} from 'gatsby'

const TeamStyleOne = () => {
     return (
            <section className="blog-details-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-content">

                                    <p>Ca echipă de dezvoltare software, suntem foarte pricepuți și experimentați în livrarea soluțiilor software de calitate înaltă. Avem o bază solidă în știința calculatoarelor și principiile ingineriei software, și suntem bine informați despre o gamă largă de limbaje de programare și tehnologii.</p>

                                      <p>  În plus față de expertiza noastră tehnică, avem, de asemenea, abilități puternice de rezolvare a problemelor și capacitatea de a gândi critic și creativ. Suntem în măsură să analizăm probleme complexe și să dezvoltăm soluții inovatoare care îndeplinesc nevoile clienților noștri.</p>

                                       <p> Comunicarea eficientă este un alt aspect important al echipei noastre. Suntem în măsură să comunicăm eficient cu clienții, managerii de proiect și alți stakeholderi în timpul procesului de dezvoltare. Acest lucru include capacitatea de a exprima clar concepte tehnice pentru audiențe ne tehnice și de a colabora eficient cu membrii echipei.</p>

                                      <p>  Suntem foarte organizați și capabili să ne gestionăm timpul eficient. Suntem în măsură să lucrăm eficient și efectiv într-un mediu dinamic și în mers rapid, și suntem în măsură să respectăm termenele limită și să livrăm rezultate de calitate înaltă la timp.</p>

                                      <p>  În cele din urmă, suntem dedicați învățării continue și dezvoltării profesionale. Suntem dispuși să rămânem la curent cu cele mai recente evoluții din domeniul nostru și să ne îmbunătățim continuu abilitățile și cunoștințele. Acest lucru ne permite să livrăm soluții software de vârf care îndeplinesc nevoile în continuă schimbare ale clienților noștri.</p>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
}

export default TeamStyleOne